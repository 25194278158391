import { ConfigService } from '@assist/shared/data';
import { coreConfig } from '@assist/shared/core';
import { importProvidersFrom, inject } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from './environments/environment';
import { provideCueAnalytics, withHeadersIntecceptor } from '@cue/analytics';
import { provideNavigationSettings } from '@cue/navigation-settings';
import { provideLocalization } from '@cue/translate';
import { localizations } from '@assist/shared/translations';

export const appConfig = (configService: ConfigService) => {
  return {
    providers: [
      coreConfig(configService).providers,
      provideNavigationSettings({
        configurationFactory: () => {
          return {
            design: configService.value.design,
          };
        },
      }),
      provideLocalization({
        localizations: localizations,
        translocoConfig: {
          availableLangs: configService.value.languages.filter((x) => x.activeInAssist).map((x) => x.code),
          defaultLang: configService.value.languages.find((x) => x.default).code,
          prodMode: false,
          reRenderOnLangChange: true,
          fallbackLang: configService.value.languages.find((x) => x.default).code,
          missingHandler: {
            useFallbackTranslation: true,
          },
        },
      }),
      provideCueAnalytics(
        withHeadersIntecceptor({
          apiDomains: () => {
            const configService = inject(ConfigService);
            return [configService.value.apiURL];
          },
          deviceIDHeaderName: 'deviceID',
          sessionHeaderName: 'sessionID',
        }),
      ),
      importProvidersFrom(
        ServiceWorkerModule.register('/ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerImmediately',
        }),
      ),
    ],
  };
};
